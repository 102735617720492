import * as React from "react";
import { FC } from "react";
import { UnsupportedBrowserMessage } from "../common/components/unsupported-browser-message/unsupported-browser-message";
import { ILocalizedPage } from "../common/types/page";
import { WelcomeLinks } from "../components/welcome/welcome-links";
import { WelcomePainting } from "../components/welcome/welcome-painting";
import messages from "../localizations";
import { LocalizationContext } from "../common/localization/localize";

export const WelcomeEntryPoint: FC<ILocalizedPage> = ({ lang }) => (
  <LocalizationContext.Provider
    value={{
      lang,
      localize: localizationKey => messages[lang][localizationKey]
    }}
  >
    <UnsupportedBrowserMessage>
      <WelcomeLinks lang={lang} />
      <WelcomePainting />
    </UnsupportedBrowserMessage>
  </LocalizationContext.Provider>
);
