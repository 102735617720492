import * as React from "react";
import { ReactNode, FC } from "react";
import {
  Heading,
  HEADING_LEVEL,
  HEADING_CLASS
} from "../../common/components/typography/heading";
import { Link } from "gatsby";
import classes from "./heading-link.module.css";

interface IHeadingLink {
  to: string;
  children: ReactNode;
}

export const HeadingLink: FC<IHeadingLink> = ({ to, children }) => {
  return (
    <div className={classes.link}>
      <Link to={to}>
        <Heading level={HEADING_LEVEL.H2} theme={HEADING_CLASS.WELCOME}>
          {children}
        </Heading>
      </Link>
    </div>
  );
};
