import * as React from "react";

export enum FLAG_NAME {
  SWISS = "flagge-ch",
  POLISH = "flagge-pl"
}

interface IFlag {
  flagName: FLAG_NAME;
  alt: string;
  width?: string;
  height: string;
}

export const Flag = ({ flagName, alt, width, height }: IFlag) => (
  <img
    src={`/welcome/${flagName}.gif`}
    alt={alt}
    width={width}
    height={height}
  />
);
