import * as React from "react";
import { FC } from "react";
import { Flag, FLAG_NAME } from "../flags/flag";
import { HeadingLink } from "./heading-link";
import { LANG } from "../../common/types/localization";
import * as classnames from "classnames";
import classes from "./welcome-link.module.css";
import { useLocalized } from "../../common/localization/localize";

interface IWelcomeLinks {
  lang: LANG;
}

export const WelcomeLinks: FC<IWelcomeLinks> = ({ lang }) => {
  const reverse = lang === LANG.plPL;
  const containerClasses = classnames(classes.container, {
    [classes.reverse]: reverse
  });
  const swissClasses = classnames(classes.swiss, {
    [classes.reverse]: reverse
  });
  const polishClasses = classnames(classes.polish, {
    [classes.reverse]: reverse
  });

  const altTextShortSignature = useLocalized("welcome_short_signature_alttext");

  return (
    <div className={containerClasses}>
      <div className={swissClasses}>
        <div className={classes.inner}>
          <Flag
            flagName={FLAG_NAME.SWISS}
            alt={"Schweizer Flagge"}
            height="40"
          />
        </div>
        <div className={classes.inner}>
          <HeadingLink to={"/de-ch/zur_person/im_atelier"}>
            Willkommen
          </HeadingLink>
        </div>
      </div>

      <div className={classes.signature}>
        <div className={classes.inner}>
          <img src="/welcome/logo.png" alt={altTextShortSignature} />
        </div>
      </div>

      <div className={polishClasses}>
        <div className={classes.inner}>
          <HeadingLink to={"/pl-pl/odnosnie_osoby/w_pracowni"}>
            Zaprasza
          </HeadingLink>
        </div>
        <div className={classes.inner}>
          <Flag
            flagName={FLAG_NAME.POLISH}
            alt={"Polnische Flagge"}
            height="40"
          />
        </div>
      </div>
    </div>
  );
};
