import * as React from "react";
import {
  Heading,
  HEADING_LEVEL,
  HEADING_CLASS
} from "../../common/components/typography/heading";

import classes from "./welcome-painting.module.css";
import { useLocalized } from "../../common/localization/localize";

export const WelcomePainting = () => {
  const altTextPainting = useLocalized("welcome_painting_alttext");
  const altTextSignature = useLocalized("welcome_signature_alttext");
  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <div className={classes.name}>
          <Heading level={HEADING_LEVEL.H1} theme={HEADING_CLASS.WELCOME}>
            Norbert Jan Paprotny
          </Heading>
        </div>
        <div className={classes.painting}>
          <img src="/welcome/welcome-painting.jpg" alt={altTextPainting} />
        </div>
        <div className={classes.signature}>
          <img src="/welcome/signature-animated.gif" alt={altTextSignature} />
        </div>
      </div>
    </div>
  );
};
